<template>
  <div
    class="zy-img-gif"
    :style="fStyle || { width: `${size}px`, height: `${size}px` }"
  >
    <img :src="imgList[index]" />
    <div class="zy-img-gif-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      imgList: [],
      timer: null,
      isCreate: false,
    }
  },
  props: {
    //宽高，这里默认宽高一致
    size: Number,
    fStyle: Object,
    //路径，gif图集，文件夹放在“@/jxyh/assets/images/”下，
    // 图片从0.png-1000.png开始,中间不能有断开的名称顺序
    path: String,
    //帧率，控制gif图速度
    fps: {
      type: Number,
      default: 24,
    },
  },
  watch: {
    path: {
      immediate: true,
      handler(val) {
        this.initImg()
      },
    },
  },
  methods: {
    async initImg() {
      if (!this.path.length) return
      for (let i = 0; i < 54; i++) {
        try {
          const name = i < 10 ? `0000${i}` : `000${i}`
          const img = require(`@/jxyh/assets/images/${this.path}/${name}.webp`)
          if (!img) {
            break
          }
          //避免不停发送请求图片，把图片信息缓存下来
          // const base64 = await this.base64(img)
          this.imgList.push(img)
        } catch (e) {
          console.error("Error: ", e)
          break
        }
      }
      setTimeout(() => {
        this.startGif()
      }, 500)
    },
    base64(url) {
      return new Promise((resolve) => {
        const image = new Image()
        image.crossOrigin = "Anonymous"
        // 再给image赋值src属性，先后顺序不能颠倒
        image.src = url
        image.onload = function () {
          const canvas = document.createElement("CANVAS")
          // 设置canvas宽高等于图片实际宽高
          canvas.width = image.width
          canvas.height = image.height
          canvas.getContext("2d").drawImage(image, 0, 0)
          const dataURL = canvas.toDataURL("image/webp")
          resolve(dataURL)
        }
        image.onerror = () => {
          resolve({ message: "相片处理失败" })
        }
      })
    },
    startGif() {
      this.index = 0
      this.timer = setInterval(() => {
        if (this.index == this.imgList.length - 1) {
          this.index = 0
        } else {
          this.index++
        }
      }, 1000 / this.fps)
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>
<style lang="less" scoped>
.zy-img-gif {
  position: relative;
  user-select: none;
  & > img {
    width: 100%;
    height: 100%;
  }
  &-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}
html {
  -webkit-tap-highlight-color: none;
}
</style>
